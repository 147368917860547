//all varibles goes under this files
$white: #fff;
$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #f1f4f5;
$scrollBarBgColor: rgba(105, 129, 155, 0.2);

$borderColor: #ccc;
$borderBgColor: #eeeeee;
$backgroundColor: #edf2f3;
$scrollBarBgColor: rgba(105, 129, 155, 0.5);
$adminLoginbackgroundColor: #525fbd;

//progress stats color
$progressError: #e55957;
$progressSuccess: #009f46;
$progressWarning: #f6841b;
$progressStrong: #ffc30b;

//progress status bg color with opacity
$progressErrorBg: rgba(229, 89, 87, 0.585);
$progressSuccessBg: rgba(0, 159, 69, 0.486);
$progressWarningBg: rgba(246, 133, 27, 0.545);
$progressStrongBg: rgba(255, 194, 11, 0.495);
$errorTextColor: #ff1943;

//table
$table-bg-color: #fff;
$table-header: rgba(18, 20, 51, 0.9);
$table-checkbox-checked: #4e2788;
$table-checkbox-unchecked: #e9e9f8;
$table-hover: rgba(233, 233, 248, 0.4);
$pagination-selected-bd: #31125f;
$pagination-font: #ffffff;
$table-sort-icon: #aeaeae;

//modal
$delete-modal-subtitle: rgb(234, 84, 85);
$card-box-border-color: rgba(200, 203, 208, 0.5);
$menuItemBorders: #f3f3f3;
$menuItemBorderShadow: 0px 6px 5.01px 0px rgba(25, 42, 75, 0.04);
