@import './variables';
@import './mixins';
@import './component/app';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import './component/modal';
@import './component/bot_modal';

//common classes goes here
body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size
.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}
.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}
.textweight {
  @include font-weight-bold;
}
.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}
//width per clasess
@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage($i/100));
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mr-#{$i} {
    margin-right: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .ml-#{$i} {
    margin-left: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{($i/16)}rem !important;
  }
}
.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important ;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;
  &.admin-login {
    background-color: $adminLoginbackgroundColor;
  }
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}
.flex_1 {
  flex: 1;
}
.overflow_x_hidden {
  overflow-x: hidden;
}
.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;
  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  overflow: auto;
}

//password strength meter
.passStrengthWeak {
  background-color: $progressErrorBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressError !important;
  }
}
.passStrengthFair {
  background-color: $progressWarningBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressWarning !important;
  }
}
.passStrengthGood {
  background-color: $progressStrongBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressStrong !important;
  }
}
.passStrengthStrong {
  background-color: $progressSuccessBg !important;
  .MuiLinearProgress-bar1Determinate {
    background-color: $progressSuccess !important;
  }
}
.errorState {
  color: $errorTextColor !important;
}
.letter-spacing {
  letter-spacing: 0.5px;
}
.disable-switch {
  opacity: 0.45;
}

//customTreeView
.customTreeView {
  overflow: auto;
  @include commonScrollBar;
  height: calc(100vh - 196px);
  .treeItemView {
    .MuiTreeItem-content {
      padding: 10px;
    }
  }
}

//File Manager Component
.fleManager {
  .breadcrumbItem {
    button {
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: 500;
      padding: 0 3px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .breadcrumb {
    border-top: 1px solid rgb(204 204 204 / 70%);
    border-bottom: 1px solid rgb(204 204 204 / 40%);
  }
  .refreshIcon {
    .MuiSvgIcon-root {
      font-size: 18px;
    }
  }
  .fileActionBar {
    background-color: #efefef;
    .iconActiveColor {
      .MuiSvgIcon-root {
        color: #5569ff !important;
      }
      .barLabel {
        color: #000 !important;
      }
    }
    .topHeaderIcon {
      .barIcons {
        .MuiSvgIcon-root {
          font-size: 40px;
          color: #bababa;
        }
      }
      .barLabel {
        font-size: 10px;
        font-weight: 400;
        color: #bababa;
      }
    }
    .MuiBox-root {
      margin-right: 10px;
      @extend .flex-basic-center;
      @extend .cursorPointer;

      // .MuiSvgIcon-root {
      //   font-size: 16px;
      //   color: #808080;
      // }
    }
  }
  .fileViewSection {
    height: calc(100vh - 199px);
    .sideTreeMenu {
      width: 280px;
      border-right: 1px solid #d7d7d7;
    }
  }
}

//cardBoxFileManager
.cardBoxFileManager {
  .MuiCardContent-root {
    padding: 0px !important;
  }
}

//fileFolderListView
.fileFolderListView {
  svg[data-testid='FolderIcon'] {
    color: #efb63c;
  }
  .fileFolder {
    &:hover {
      background-color: #edefef;
    }
    &.active {
      background-color: #dae4f3;
      border: 1px solid #c5d4eb;
    }
    .MuiTypography-subtitle1 {
      color: #191919;
    }
  }
}

//dropMenuView
.dropMenuView {
  .MuiMenu-paper {
    border: 1px solid $card-box-border-color !important;
    background: $white;
    box-shadow: $menuItemBorderShadow !important;
    ul.MuiMenu-list {
      padding: 0px;
      li {
        border-bottom: 1px solid $menuItemBorders;
        @include border-radius(0px);
        margin: 0px;
        padding: 10px 36px;
        color: #000;
      }
      hr.MuiDivider-fullWidth {
        display: none;
      }
    }
  }
}

//top header logo
.HeaderWrapper {
  &.topHeaderBar {
    .MuiContainer-root {
      padding-left: 6px !important;
    }
  }
}
